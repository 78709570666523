<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 language-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("global", "template", "template") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="languages" v-if="templates">
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="deviceTemplate in templates"
          :key="deviceTemplate.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectDeviceTemplate(deviceTemplate)"
          >
            <span class="label">{{ deviceTemplate.name }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  :icon="[
                    selectedDeviceTemplate &&
                    deviceTemplate.id == selectedDeviceTemplate.id
                      ? '#cx-app1-checkmark'
                      : '#cx-app1-arrow-right-12x12',
                  ]"
                  :width="[
                    selectedDeviceTemplate &&
                    deviceTemplate.id == selectedDeviceTemplate.id
                      ? '20'
                      : '12',
                  ]"
                  :height="[
                    selectedDeviceTemplate &&
                    deviceTemplate.id == selectedDeviceTemplate.id
                      ? '20'
                      : '12',
                  ]"
                ></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState } from "vuex";

export default {
  name: "DeviceTemplates",
  data() {
    return {
      templates: null,
    };
  },
  created() {
    if (this.$route.name == "r_edit-resource-edit-function-device-template") {
      this.templates = this.deviceTemplates.data.filter(
        (el) => el.device_type == "DISPLAY"
      );
    } else {
      this.templates = this.deviceTemplates.data.filter(
        (el) => el.device_type == "RECEPTION_DESK"
      );
    }
  },
  computed: {
    ...mapState("deviceTemplates", [
      "deviceTemplates",
      "selectedDeviceTemplate",
    ]),
  },
  methods: {
    ...mapActions("deviceTemplates", [
      "getDeviceTemplates",
      "setSelectedDeviceTemplate",
    ]),
    selectDeviceTemplate(template) {
      this.setSelectedDeviceTemplate(template);
      this.$router.push({ name: this.backLinkName });
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
